import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('../pages/home')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../pages/contact')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../pages/about')
    },
    {
      path: '/join',
      name: 'join',
      component: () => import('../pages/join')
    },
    {
      path: '/tech',
      name: 'tech',
      component: () => import('../pages/tech')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('../pages/news')
    },
    {
      path: '/detail',
      name: 'detail',
      component: () => import('../pages/detail')
    },
    {
      path: '/product',
      name: 'product',
      component: () => import('../pages/product')
    },
    {
      path: '/product-spec',
      name: 'product-spec',
      component: () => import('../pages/product-spec')
    },
    {
      path: '/download',
      name: 'download',
      component: () => import('../pages/download')
    },
  ],
})

export default router