<template>
  <div :class="['footer', $store.state.lang !== 'zh' ? 'footer-en' : '']">
    <!-- 菜单代码 -->
    <div class="footer-menu">
      <div class="footer-menu-item">
        <h4 class="menu-item-title">{{ $t('footer.msg_1') }}</h4>
        <p class="menu-item-line qrcode-container">
          <img class="qrcode" src="../assets/images/qrcode.png" alt="" />
          <span class="empty-span">/</span>
          {{ $t('footer.msg_2') }}
        </p>
        <p class="menu-item-line" @click="jump('tech')"><span class="empty-span">/</span>{{ $t('footer.msg_3') }}</p>
        <p class="menu-item-line" @click="jump('news')"><span class="empty-span">/</span>{{ $t('footer.msg_4') }}</p>
        <p class="menu-item-line" @click="jump('contact')"><span class="empty-span">/</span>{{ $t('footer.msg_5') }}</p>
        <p class="menu-item-line qrcode-container-phone">
          <span class="empty-span">/</span>
          {{ $t('footer.msg_6') }}
          <img class="qrcode" src="../assets/images/qrcode.png" alt="" />
        </p>
      </div>
      <div class="footer-menu-item">
        <h4 class="menu-item-title">{{ $t('footer.msg_7') }}</h4>
        <p class="menu-item-line" @click="jump('product')"><span class="empty-span">/</span>{{ $t('footer.msg_8') }}</p>
        <p class="menu-item-line" @click="jump('product')"><span class="empty-span">/</span>{{ $t('footer.msg_9') }}</p>
        <p class="menu-item-line" @click="jump('product')"><span class="empty-span">/</span>{{ $t('footer.msg_10') }}</p>
         <p class="menu-item-line" @click="jump('download')"><span class="empty-span">/</span>{{ $t('footer.msg_17') }}</p>
      </div>
      <div class="footer-menu-item">
        <h4 class="menu-item-title">{{ $t('footer.msg_11') }}</h4>
        <p class="menu-item-line" @click="jump('join')"><span class="empty-span">/</span>{{ $t('footer.msg_12') }}</p>
        <p class="menu-item-line" @click="jump('contact')"><span class="empty-span">/</span>{{ $t('footer.msg_13') }}</p>
      </div>
    </div>
    <div class="footer-info">
      <!-- 底部logo -->
      <img class="footer-info-logo" src="../assets/images/foot-logo@2x.png" alt="">
      <!-- 底部标题-公司名称 -->
      <p class="footer-info-title"><span class="info-title-line">/&nbsp;/</span>{{ $t('footer.msg_14') }}</p>
      <!-- 底部图标 -->
      <div class="footer-info-content">
        <div class="info-content-icon">
          <span class="icon-1">&lt;</span>
          <span class="icon-2">A</span>
          <span class="icon-3">//</span>
          <span class="icon-4">&gt;</span>
        </div>
        <div class="info-content-address">{{ $t('footer.msg_15') }}</div>
      </div>
      <!-- 底部 备案 -->
      <p class="footer-info-record">
        <span class="info-record-year">© BOTIFY 2021 </span>
        <span class="info-record-detail"><a href="https://beian.miit.gov.cn/">{{ $t('footer.msg_16') }}</a></span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    // 跳转
    jump (path) {
      this.$router.push({
        path: '/' + path
      })
    },
  },
}
</script>

<style lang="less" scope>
.footer {
  background: #323037;
}
// 大屏样式
@media (min-width: 1001px) {
  .empty-span {
    display: inline-block;
    width: .2rem;
  }

  .footer {
    height: 4.8rem;
    padding: 0 1.5rem;

    .footer-menu {
      width: 50%;
      float: right;
      padding-top: 1rem;

      .footer-menu-item {
        float: left;
        margin-left: 1.5rem;
        font-style: oblique;

        .menu-item-title {
          font-size: 'SourceHanSansCNBold';
          color: #fff;
          font-size: .24rem;
          height: 1rem;
          line-height: 1rem;
        }

        .menu-item-line {
          color: #fff;
          font-size: .2rem;
          height: .4rem;
          line-height: .4rem;
          cursor: pointer;
        }

        .qrcode-container {
          position: relative;
          .qrcode {
            display: none;
            position: absolute;
            width: 1.4rem;
            height: 1.4rem;
            top: -1.4rem;
            right: -1.5rem;
          }
        }

        .qrcode-container:hover {
          .qrcode {
            display: block;
          }
        }

        .qrcode-container-phone {
          display: none;
        }
      }
    }

    .footer-info {
      .footer-info-logo {
        width: 2.42rem;
        height: .5rem;
        margin-top: 1.04rem;
      }
      .footer-info-title {
        color: #F2F3F4;
        margin-top: .56rem;
        font-size: .2rem;

        .info-title-line {
          padding-right: .1rem;
        }
      }
      .footer-info-content {
        font-size: .2rem;
        margin-top: .26rem;
        height: .7rem;

        .info-content-icon {
          width: 1rem;
          height: .36rem;
          line-height: .36rem;
          float: left;
          .icon-1 {
            display: inline-block;
            color: #006DA0;
          }

          .icon-2 {
            display: inline-block;
            width: .2rem;
            text-align: center;
          }

          .icon-3 {
            display: inline-block;
            padding-left: .1rem;
            color: #006DA0;
          }

          .icon-4 {
            display: inline-block;
            padding-left: 0.05rem;
            color: #006DA0;
          }
        }

        .info-content-address {
          float: left;
          margin-left: .6rem;
          width: 3.4rem;
          height: .36rem;
          line-height: .36rem;
        }
      }

      .footer-info-record {
        margin-top: .5rem;
        .info-record-year {
          color: #006B9C;
          font-size: 'SourceHanSansCNBold';
        }
        .info-record-detail {
          padding-left: .3rem;
          color: #006B9C;
          font-size: 'SourceHanSansCNRegular';
        }
      }
    }
  }


  .footer-en {
    height: 6rem!important;

    .footer-info-record {
      margin-top: 1.2rem!important;
      line-height: .36rem!important;
    }
  }
}
// 小屏样式
@media (max-width: 1000px) {
  .empty-span {
    display: inline-block;
    width: .2rem;
  }

  .footer {
    padding: 0 .42rem;

    .footer-menu {
      padding-top: .7rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .footer-menu-item {
        flex: 1;
        font-style: oblique;
        padding-left: .5rem;

        .menu-item-title {
          font-size: 'SourceHanSansCNBold';
          color: #fff;
          font-size: .28rem;
          height: .9rem;
          line-height: .9rem;
        }

        .menu-item-line {
          color: #fff;
          font-size: .22rem;
          height: .5rem;
          line-height: .5rem;
          cursor: pointer;
        }

        .qrcode-container {
          display: none;
          position: relative;
          height: 2rem;
          .qrcode {
            display: block;
            width: 1.4rem;
            height: 1.4rem;
            margin-bottom: .1rem;
          }
        }

        .qrcode-container-phone {
          display: block;
          position: relative;
          height: 2rem;
          .qrcode {
            display: block;
            width: 1.4rem;
            height: 1.4rem;
            margin-top: .1rem;
          }
        }
      }
    }

    .footer-info {
      .footer-info-logo {
        width: 2.42rem;
        height: .5rem;
        margin-top: .58rem;
      }
      .footer-info-title {
        color: #F2F3F4;
        margin-top: .56rem;
        font-size: .2rem;

        .info-title-line {
          padding-right: .1rem;
        }
      }
      .footer-info-content {
        font-size: .2rem;
        margin-top: .26rem;
        height: .36rem;

        .info-content-icon {
          width: 1rem;
          height: .36rem;
          line-height: .36rem;
          float: left;
          .icon-1 {
            display: inline-block;
            color: #006DA0;
          }

          .icon-2 {
            display: inline-block;
            width: .2rem;
            text-align: center;
          }

          .icon-3 {
            display: inline-block;
            padding-left: .1rem;
            color: #006DA0;
          }

          .icon-4 {
            display: inline-block;
            padding-left: 0.05rem;
            color: #006DA0;
          }
        }

        .info-content-address {
          float: left;
          margin-left: .6rem;
          height: .36rem;
          line-height: .36rem;
        }
      }

      .footer-info-record {
        margin-top: .3rem;
        padding-bottom: 1.18rem;

        .info-record-year {
          color: #006B9C;
          font-size: 'SourceHanSansCNBold';
        }
        .info-record-detail {
          padding-left: .3rem;
          color: #006B9C;
          font-size: 'SourceHanSansCNRegular';
        }
      }
    }
  }


  .footer-en {

    .footer-info-record {
      margin-top: 1.2rem!important;
      line-height: .36rem!important;
    }
  }
}
</style>
