import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 提供初始 state 对象
const store = new Vuex.Store({
  state: {
   // lang: 'zh'
    lang: 'zh'
  },
  getters: {
    lang: state => state.lang,
  },
  mutations: {
    setLang(state, val) {
      state.lang = val
    },
  },
// 现在，你可以通过 store.state 来获取状态对象，以及通过 store.commit 方法触发状态变更：
  actions: {
    changeLang({ commit }) {
      commit('setLang')
    },
  }
})

export default store