const message = {
  msg_1: 'About Us',
  msg_2: 'WeChat public account',
  msg_3: `Technology`,
  msg_4: 'News',
  msg_5: 'Contact us',
  msg_6: 'WeChat public account',
  msg_7: 'Product',
  msg_8: 'Design',
  msg_9: 'Intelligent',
  msg_10: 'Efficient',
  msg_11: 'Contact',
  msg_12: 'Join us',
  msg_13: 'Business Cooperation',
  msg_14: 'Huizhi Robot Technology (Shenzhen) Co., Ltd.',
  msg_15: '7F, Block B, Wanhai Building, 1031 Nanhai Avenue, Nanshan District, Shenzhen',
  msg_16: 'Guangdong ICP No. 2020125840-1',
  msg_17: 'APP Download',
}

export default message