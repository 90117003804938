const message = {
  msg_1: 'Vacant',
  msg_2: 'Recruitment',
  msg_3: 'position',
  msg_4: 'Department category',
  msg_5: 'Work place',
  msg_6: 'Job type',
  msg_7: 'Time',
  msg_8: 'If you are interested, please send your resume to',
}

export default message