const message = {
  msg_1: 'Technical Parameters',
  msg_2: 'Indoor parking, no other choice',
  msg_3: `Comity`,
  msg_4: 'Crossing the speed bump',
  msg_5: 'Cross-layer',
  msg_6: 'Hand-in-one, make full use of',
  msg_7: 'Built-in screen on steering wheel',
  msg_8: 'Automatic replenishment',
  msg_9: 'Supporting APP',
  msg_10: 'Super high efficiency, saving time and cost',
  msg_11: 'Cleaning efficiency',
  msg_12: 'Management efficiency',
  msg_13: 'Comity',
  msg_14: 'Do cleaning without affecting the parking of the bosses',
  msg_15: 'The first principle of cleaning work is not to affect the main business, and to be courteous to the cars coming and going. Whether it is at a turn or at a T-shaped/intersection, you can choose the most suitable stopping point without affecting the normal parking of the owners and customers. Can also do a good job of cleaning their own jobs.',
  msg_16: 'Before and after comity',
  msg_17: 'Comity around the corner',
  msg_18: 'T-shaped/crossroads comity',
  msg_19: 'Scenario strategy',
  msg_20: 'Crossing the speed bump',
  msg_21: 'Across the slope',
  msg_22: 'Self-protection, no trouble to users',
  msg_23: 'From B1 to B3, there is no need for people to transition',
  msg_24: `The indoor parking lot has a complex environment, and any carelessness will damage the machine. It can actively slow down and lift the operating mechanism when passing through the speed bump/safety door/drain tank to protect itself, which is to protect the user's performance output.`,
  msg_25: 'Slope is a scene that almost all indoor parking lots will encounter. Being able to cross the slope autonomously will have the ability to freely shuttle between different floors. Even if it is a cross-story task, manual transitions are no longer required.',
  msg_26: 'Hand-in-one',
  msg_27: 'automatic',
  msg_28: 'Manual',
  msg_29: 'Automatic, is a highly automated unmanned scrubber',
  msg_30: 'Manual, also an outstanding ride-on scrubber',
  msg_31: 'It can not only automatically clean without people, but also automatically handle various obstacles to people and vehicles in the cleaning process, and even discharge sewage, add clean water, and charge can also be automated.',
  msg_32: 'It is inevitable that there are areas that cannot be covered in automatic mode, and manual cleaning will also be part of the cleaning. A ride-on scrubber can make users less fatigued when cleaning for a long time and guarantee the cleaning quality.',
  msg_33: 'Human-machine collaboration',
  msg_34: 'Each has its own strengths, cooperation is king',
  msg_35: 'The human-machine fusion design, the steering wheel is embedded with a touch screen, and the physical start-stop button allows seamless switching between manual operation and automatic cleaning; collaboration and abnormalities can be communicated through the mobile terminal, making human-machine collaboration natural.',
  msg_36: 'Excellent efficiency',
  msg_37: 'Charge 1 time, battery life for 5 hours, covering 10,000 square meters',
  msg_38: 'The daily coverage area can reach 17,500 square meters, which is especially meaningful for large-area scenes, which means that it can take less cost and less time to achieve the same or even higher cleaning coverage frequency.',
  msg_39: 'Digital management',
  msg_40: 'The result of the work goes from unknowable to knowable, from knowable to quantifiable',
  msg_41: 'The supporting mobile APP can digitally present the business results in hands, and provide a useful scale for the refined operation of cleaning.',
}

export default message