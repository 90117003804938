const message = {
  msg_1: '安全，看得更全面',
  msg_2: '通过遍布周身的传感设计，机器可以全面感知环境，确保机器在人机共融环境中作业时的安全性。',
  msg_3: '激光雷达',
  msg_4: '360度 100米',
  msg_5: '深度相机',
  msg_6: '80度 3米',
  msg_7: '高清摄像头',
  msg_8: '120度',
  msg_9: '超声波',
  msg_10: '50度 1米',
  msg_11: '安全触边',
  msg_12: '专业，更理解场景',
  msg_13: '通过全方位的感知技术，机器可以识别物体并作出规划，确保机器能够有效处理问题，兼顾运行体验和作业效率。',
  msg_14: '物体识别',
  msg_15: '区分人、车、普通障碍——根据不同物体',
  msg_16: '实现专属的执行策略 （等待绕障、主动退避、直接绕障）',
  msg_17: '环境识别',
  msg_18: '可通行区域、减速带、挡轮杆等——根据不同环境特征',
  msg_19: '实现专属的跨越策略（正常作业、降速跨越、绕障）',
  msg_20: '高效，数字孪生',
  msg_21: '通过全方位的感知技术，机器可以识别物体并作出规划，确保机器能够有效处理问题，兼顾运行体验和作业效率。',
}

export default message