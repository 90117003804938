const message = {
  msg_1: '汇智万物',
  msg_2: '使工具更智能，让工作更轻松',
  msg_3: '汇智万物APP是数字化和无人化清洁的管理工具，为一线的操作人员提供了人机协同和远程设备管理工具。为清洁管理和运营人员提供了数字化工具，可以高效的管理清洁业务，将过去难以量化的清洁业务用数字表现出来。主要功能包含对清洁项目的管理、对清洁设备的管理、对清洁人员的管理等。',
  msg_4: '支持机型',
  msg_5: 'iOS系统',
  msg_6: '需要 iOS 11.0 或更高版本。',
  msg_7: '兼容支持机型',
  msg_8: 'Android系统',
  msg_9: '需要 Android 5.0 或更高版本。',
  msg_10: '兼容支持机型',
  msg_11: '支持的设备列表会持续更新和增加；具体产品的功能使用兼容性请以相应产品页面显示为准。',
}

export default message