const message = {
  msg_1: '关于我们',
  msg_2: '微信公众号',
  msg_3: '技术',
  msg_4: '新闻',
  msg_5: '联系我们',
  msg_6: '微信公众号',
  msg_7: '产品',
  msg_8: '设计',
  msg_9: '智能',
  msg_10: '高效',
  msg_11: '联系',
  msg_12: '加入我们',
  msg_13: '商务合作',
  msg_14: '汇智机器人科技（深圳）有限公司',
  msg_15: '深圳市南山区南海大道1031号万海大厦B座7F',
  msg_16: '粤ICP备2020125840号-1',
  msg_17: 'APP 下载',
}

export default message