const message = {
  msg_1: '虚位以待',
  msg_2: '招聘职位',
  msg_3: '职位',
  msg_4: '部门类别',
  msg_5: '工作地点',
  msg_6: '工作类型',
  msg_7: '时间',
  msg_8: '如有兴趣，欢迎投递简历至',
}

export default message