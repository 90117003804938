const message = {
  msg_1: 'Huizhi Everything',
  msg_2: 'Make tools smarter, make work easier',
  msg_3: 'Huizhi Wanwu APP is a digital and unmanned cleaning management tool that provides human-machine collaboration and remote equipment management tools for front-line operators. Digital tools are provided for cleaning management and operation personnel, which can efficiently manage cleaning business and express the cleaning business that was difficult to quantify in the past with numbers. The main functions include the management of cleaning projects, the management of cleaning equipment, and the management of cleaning personnel.',
  msg_4: 'Support models',
  msg_5: 'iOS system',
  msg_6: 'Requires iOS 11.0 or higher.',
  msg_7: 'Compatible with supported models',
  msg_8: 'Android system',
  msg_9: 'Requires Android 5.0 or higher.',
  msg_10: 'Compatible with supported models',
  msg_11: 'The list of supported devices will continue to be updated and added; please refer to the corresponding product page for the compatibility of specific products.',
}

export default message