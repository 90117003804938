<template>
  <div class="nav">
    <div class="nav-logo" @click="jump('home')">
      <img class="nav-logo-image" src="../assets/images/nav-logo@2x.png" alt="">
    </div>
    <!-- 大屏展示menu -->
    <div class="nav-menu">
      <ul>
        <li @click="changeLanguage()" class="lang">
          <span :class="['lang-zh', $store.state.lang === 'zh' ? 'lang-color' : '']">中文</span>
          <span class="lang-icon">/</span>
          <span :class="['lang-en', $store.state.lang !== 'zh' ? 'lang-color' : '']">EN</span>
        </li>
        <li @click="jump('contact')">
          <span :class="['nav-menu-icon', active === 'contact' ? 'nav-menu-icon-active' : '']">
            <img src="../assets/images/nav-icon@2x.png" alt="">
          </span>
          {{ $t('nav.msg_1') }}
        </li>
        <li @click="jump('news')">
          <span :class="['nav-menu-icon', active === 'news' ? 'nav-menu-icon-active' : '']">
            <img src="../assets/images/nav-icon@2x.png" alt="">
          </span>
          {{ $t('nav.msg_2') }}
        </li>
        <li @click="jump('join')">
          <span :class="['nav-menu-icon', active === 'join' ? 'nav-menu-icon-active' : '']">
            <img src="../assets/images/nav-icon@2x.png" alt="">
          </span>
          {{ $t('nav.msg_3') }}
        </li>
        <li @click="jump('about')">
           <span :class="['nav-menu-icon', active === 'about' ? 'nav-menu-icon-active' : '']">
            <img src="../assets/images/nav-icon@2x.png" alt="">
          </span>
          {{ $t('nav.msg_4') }}
        </li>
        <li @click="jump('product')">
           <span :class="['nav-menu-icon', active === 'product' || active === 'product-spec' ? 'nav-menu-icon-active' : '']">
            <img src="../assets/images/nav-icon@2x.png" alt="">
          </span>
          BR-W950
        </li>
        <li @click="jump('tech')">
           <span :class="['nav-menu-icon', active === 'tech' ? 'nav-menu-icon-active' : '']">
            <img src="../assets/images/nav-icon@2x.png" alt="">
          </span>
          {{ $t('nav.msg_5') }}
        </li>
      </ul>
    </div>
    <!-- 小屏展示menu -->
    <div class="nav-phone-menu" @touchmove.prevent @click="openMenu()">
       <img class="nav-phone-menu-image" src="../assets/images/menu_icon@2x.png" alt="">
    </div>
    <transition name="fade">
      <div class="nav-phone-list" @touchmove.prevent v-if="isOpen">
        <div
          :class="['nav-phone-list-item', active === 'tech' ? 'nav-phone-list-item-active' : '']"
          @click="jump('tech')"
        >
          {{ $t('nav.msg_5') }}
        </div>
        <div
          :class="['nav-phone-list-item', active === 'product' || active === 'product-spec' ? 'nav-phone-list-item-active' : '']"
          @click="jump('product')"
        >
          BR-W950
        </div>
        <div
          :class="['nav-phone-list-item', active === 'about' ? 'nav-phone-list-item-active' : '']"
          @click="jump('about')"
        >
          {{ $t('nav.msg_4') }}
        </div>
        <div
          :class="['nav-phone-list-item', active === 'join' ? 'nav-phone-list-item-active' : '']"
          @click="jump('join')"
        >
          {{ $t('nav.msg_3') }}
        </div>
        <div
          :class="['nav-phone-list-item', active === 'news' ? 'nav-phone-list-item-active' : '']"
          @click="jump('news')"
        >
          {{ $t('nav.msg_2') }}
        </div>
        <div
          :class="['nav-phone-list-item', active === 'contact' ? 'nav-phone-list-item-active' : '']"
          @click="jump('contact')"
        >
          {{ $t('nav.msg_1') }}
        </div>
        <div
          :class="['nav-phone-list-item']"
          @click="phoneChangeLang()"
        >
          {{ $store.state.lang === 'en' ? '中文版 CHINESE' : '英文版 ENGLISH' }}
        </div>
      </div>
    </transition>
    
  </div>
</template>

<script>
import { changeLang } from '../common/utils'

export default {
  data () {
    return {
      isOpen: false,
      active: ''
    }
  },
  watch: {
    $route () {
      this.getActived()
    }
  },
  mounted() {
    this.getActived()
   //默认英文
   // if(this.$store.state.lang === 'zh') {
   //   this.changeLanguage()
   // }

  },
  methods: {
    changeLanguage () {
      const lang = this.$store.state.lang === 'zh' ? 'en' : 'zh'
      changeLang(this, lang)
      this.$store.commit('setLang', lang)
    },
    getActived () {
      const hash = window.location.hash
      if (hash.includes('/home')) {
        this.active = 'home'
      } else if (hash.includes('/contact')) {
        this.active = 'contact'
      } else if (hash.includes('/about')) {
        this.active = 'about'
      } else if (hash.includes('/join')) {
        this.active = 'join'
      } else if (hash.includes('/tech')) {
        this.active = 'tech'
      } else if (hash.includes('/news')) {
        this.active = 'news'
      } else if (hash.includes('/product-spec')) {
        this.active = 'product-spec'
      } else if (hash.includes('/product')) {
        this.active = 'product'
      } else {
        this.active = ''
      }
     // this.changeLanguage();
    },
    // 展开关闭小菜单
    openMenu () {
      // 滚动到最顶部
      document.body.scrollTop = document.documentElement.scrollTop = 0
      this.isOpen = !this.isOpen
    },
    // 跳转
    jump (path) {
      if (this.isOpen) {
        setTimeout(() => {
          this.isOpen = false
        }, 300)
      }
      this.$router.push({
        path: '/' + path
      })
    },
    // 跳转
    phoneChangeLang () {
      if (this.isOpen) {
        setTimeout(() => {
          this.isOpen = false
        }, 300)
      }
      const lang = this.$store.state.lang === 'zh' ? 'en' : 'zh'
      changeLang(this, lang)
      this.$store.commit('setLang', lang)
    },
  },
}
</script>

<style lang="less" scope>

.active {
  display: block;
}
// 大屏样式
@media (min-width: 1001px) {
  .nav {
    height: .8rem;
    padding: 0 1.5rem;

    .nav-logo {
      cursor: pointer;
      float: left;
      padding-top: .2rem;

      .nav-logo-image {
        width: 1.93rem;
        height: .4rem;
      }
    }

    .nav-menu {
      float: right;
      font-size: .22rem;

      .lang {
        margin-left: .42rem;
        font-size: .2rem;
        font-family: 'SourceHanSansCNBold';

        .lang-icon {
          padding: 0 .05rem;
        }

        .lang-color {
          color: #0E6794;
        }
      }

      ul {

        li:hover {
          .nav-menu-icon {
            img {
              display: block;
            }
          }
        }

        .nav-menu-icon-active {
          img {
            display: block!important;;
          }
        }

        li {
          display: block;
          float: right;
          height: .8rem;
          line-height: .8rem;
          margin-left: .42rem;
          cursor: pointer;

          .nav-menu-icon {
            display: inline-block;
            width: .13rem;
            height: .2rem;

            img {
              display: none;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .nav-phone-menu {
      display: none;
    }

    .nav-phone-list {
      display: none;
    }
  }
}
// 小屏样式
@media (max-width: 1000px) {
  .nav {
    height: 1rem;
    padding: 0 .4rem;
    position: relative;

    .nav-logo {
      cursor: pointer;
      float: left;
      padding-top: .3rem;

      .nav-logo-image {
        width: 1.93rem;
        height: .4rem;
      }
    }

    .nav-menu {
      display: none;
    }

    .nav-phone-menu {
      position: absolute;
      width: 1rem;
      height: 1rem;
      right: 0;
      top: 0;

      .nav-phone-menu-image {
        padding-left: .33rem;
        padding-top: .38rem;
        display: block;
        width: .34rem;
        height: .24rem;
      }
    }

    .nav-phone-list {
      height: calc(100vh - 1rem);
      width: 100vw;
      position: fixed;
      top: 1rem;
      left: 0;
      background-color: #40444A;
      font-size: .42rem;
      text-align: center;
      color: #F0F2F4;
      z-index: 10000000;
      overflow: hidden;

      .nav-phone-list-item {
        height: 1.28rem;
        line-height: 1.28rem;
      }

      .nav-phone-list-item-active {
        color: #F0F2F4;
        background-color: #0d638c;
      }
    }

    .fade-enter-active, .fade-leave-active {
      transition: height .5s;
    }
    .fade-enter, .fade-leave-to {
      // opacity: 0;
      height: 0;
    }

    .fade-enter-to, .fade-leave {
      // opacity: 0;
      // height: 0;
      height: calc(100vh - 1rem)!important;
    }
  }
}
</style>