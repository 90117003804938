const message = {
  msg_1: 'BOTIFY汇智定位于工具智能化技术服务商，通过以机器人为代表的智能化技术，赋能工具，用“机器+人”方式，解决劳动密集型行业人力短缺，作业质量不高，成本持续提升的问题，让从业者的工作更有尊严。',
  msg_2: '保洁痛点',
  msg_3: '招不到人',
  msg_4: '管不住人',
  msg_5: '保洁行业一直在和劳动力市场博弈，21世纪20年代，最大挑战不仅是人力成本刚性上升，还面临保洁人力资源的极度匮乏。长期枯燥、恶劣、低薪的工作，使保洁行业无法补充新鲜血液。人员缺岗成了保洁行业的普遍问题，服务一致性难以解决。除此之外，缺乏数字化管理工具，增加了保洁管理人员的负担，对基层保洁人员难以管控。',
  msg_6: '无人化解决方案',
  msg_7: 'BOTIFY汇智致力于解决保洁行业的服务一致性、人力短缺、人力成本刚性上升、缺乏数字化管理工具的问题，用技术为工具和组织赋能，为客户提供无人化、数字化的清洁解决方案。',
  msg_8: '无人洗地机',
  msg_9: '室内全场景高效地面清洗',
  msg_10: '室内停车场，无他之选',
  msg_11: '手自一体，充分利用',
  msg_12: '超高效率，省时降本',
  msg_13: '了解更多',
  msg_14: '阅读原文',
}

export default message